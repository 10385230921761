// extracted by mini-css-extract-plugin
export var column = "UserNetworkSdWanProject_Details_LocationDocuments__column__KzGAU";
export var container = "UserNetworkSdWanProject_Details_LocationDocuments__container__NRL6I";
export var descriptionContent = "UserNetworkSdWanProject_Details_LocationDocuments__descriptionContent__gElKV";
export var documentsHeading = "UserNetworkSdWanProject_Details_LocationDocuments__documentsHeading__aPSPR";
export var downloadButton = "UserNetworkSdWanProject_Details_LocationDocuments__downloadButton__xaxoj";
export var fileName = "UserNetworkSdWanProject_Details_LocationDocuments__fileName__pp1jl";
export var fileNameAndIconWrapper = "UserNetworkSdWanProject_Details_LocationDocuments__fileNameAndIconWrapper__vbqsX";
export var geographicalRegion = "UserNetworkSdWanProject_Details_LocationDocuments__geographicalRegion__WW42C";
export var geographicalRegionAndIconWrapper = "UserNetworkSdWanProject_Details_LocationDocuments__geographicalRegionAndIconWrapper__E98vG";
export var geographicalRegionRow = "UserNetworkSdWanProject_Details_LocationDocuments__geographicalRegionRow__zBls2";
export var geographicalRegionTitle = "UserNetworkSdWanProject_Details_LocationDocuments__geographicalRegionTitle__nZ786";
export var icon = "UserNetworkSdWanProject_Details_LocationDocuments__icon__eE7ly";
export var panelBody = "UserNetworkSdWanProject_Details_LocationDocuments__panelBody__LA6v4";
export var panelContainer = "UserNetworkSdWanProject_Details_LocationDocuments__panelContainer__iXO7r";
export var row = "UserNetworkSdWanProject_Details_LocationDocuments__row__gs7tT";